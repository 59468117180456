import { useTranslations } from 'hooks';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

const CounterRender = forwardRef(
	(
		{
			icon,
			title,
			description,
			link = '#',
			number,
			style,
			className,
			onMouseDown,
			onMouseUp,
			onTouchEnd,
			children,
			...props
		},
		ref,
	) => {
		const { translate } = useTranslations();
		return (
			<div
				className={`bg-white rounded-md p-4 shadow-md flex flex-col justify-center w-auto relative overflow-hidden ${className}`}
				ref={ref}
				onMouseDown={onMouseDown}
				onMouseUp={onMouseUp}
				onTouchEnd={onTouchEnd}
				style={style}
				{...props}
			>
				<div className='flex flex-row justify-between items-center relative -mt-5'>
					{/* Same icon at background with text gray a bit rotated but very big */}
					<div
						style={{ fontSize: '16.5rem', top: '50%', left: '30%', transform: 'translate(-50%, -50%)' }}
						className='absolute text-gray-200 opacity-30'
					>
						<i className={icon} />
					</div>

					<div className='text-5xl text-primary-500 mx-4 z-10 hover:text-primary-700'>
						<i className={icon} />
					</div>
					<div className='pr-3 z-10'>
						<div className='text-lg font-semibold text-gray-700 mb-1 uppercase text-end'>
							{translate(title, true)}
						</div>
						{description && (
							<div className='text-sm font-thin text-gray-400  text-end'>
								{translate(description, true)}
							</div>
						)}
						<div className='text-4xl text-right font-bold text-gray-900 mt-1'>{number}</div>
					</div>
				</div>

				<div className='border-b border-gray-200 absolute bottom-9  w-full'></div>

				<div className='cursor-pointer text-primary-400 hover:text-primary-600  text-xs text-end  absolute bottom-3 right-5'>
					<Link to={link}>{translate('viewMore')}...</Link>
				</div>
				{children}
			</div>
		);
	},
);

export default CounterRender;
