import { DescriptionColumn, IdColumn, NameColumn } from 'core/columns';
import { BooleanColumn } from 'core/columns/baseColumns';
import BaseModel from 'core/models/BaseModel';
class Model extends BaseModel {
	constructor() {
		super();
		this.modelName = 'taskAutomationRule';
		this.pluralModelName = 'taskAutomationRules';
	}

	initializeColumns() {
		this.columns.push(new IdColumn());
		this.columns.push(new NameColumn('name'));
		this.columns.push(new BooleanColumn('enabled'));
		this.columns.push(new DescriptionColumn());
		this.assignColumnOrder();
	}
	isExportable() {
		return false;
	}

	areSelectsVisible() {
		return false;
	}
}

export default Model;
