import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { Button } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useTranslations } from 'hooks';
import { runAction } from 'modules/utils';
import { LEVELS, loadChildren } from '../services/addresses';

const AddressSelectorPortal = forwardRef((props, ref) => {
	const { translate } = useTranslations();

	const [show, setShow] = useState(false);
	const [addresses, setAddresses] = useState([]);
	const [selected, setSelected] = useState(null);
	const [settings, setSettings] = useState(null);
	const [activeLevel, setActiveLevel] = useState(props.level);
	const [activeId, setActiveId] = useState(props.defaultId);
	const scrollContainerRef = useRef(null);
	const [selectedItems, setSelectedItems] = useState([]);
	const [endReached, setEndReached] = useState(false);
	const [baseSelectionOnly, setBaseSelectionOnly] = useState(false);
	const [selectDisabled, setSelectDisabled] = useState(false);
	const [loading, setLoading] = useState(true);
	const [rootLevel, setRootLevel] = useState(6);

	// On address click (use it to highlight, or load next level)
	const selectAddress = (addr) => {
		if (baseSelectionOnly) {
			if (settings.baseAddressLevel >= addr.level) {
				setSelectDisabled(false);
			} else {
				setSelectDisabled(true);
			}
		}
		setSelected(addr);
		setSelectedItems((prev) => {
			let updatedItems = [...prev];
			updatedItems = updatedItems.slice(0, mapLevels(addr.level) + 1);
			updatedItems[mapLevels(addr.level) + 1] = addr.id;
			return updatedItems;
		});

		if (addr.level > 1) loadAddresses(mapLevels(addr.level - 1), addr.id);
	};

	const formatAddress = () => {
		const buildNestedAddress = (index) => {
			if (index >= selectedItems.length) {
				return null;
			}
			const item = selectedItems[index];
			const address = addresses[index].find((addr) => addr.id === item);
			return {
				child: buildNestedAddress(index + 1),
				parentId: address.parentId,
				level: address.level,
				id: address.id,
				name: address.name,
			};
		};
		return buildNestedAddress(mapLevels(rootLevel) + 1);
	};

	const onSelect = useRef(null);

	const loadAddresses = async (l_activeLevel = activeLevel, l_activeId = activeId) => {
		setLoading(true);
		if (l_activeLevel !== null) {
			await loadChildren(l_activeLevel, l_activeId).then((res) => {
				setLoading(false);
				if (res.data.length > 0) {
					setAddresses((prev) => {
						let updatedAddresses = [...prev];
						updatedAddresses = updatedAddresses.slice(0, l_activeLevel + 1);
						updatedAddresses[l_activeLevel + 1] = res.data;
						return updatedAddresses;
					});

					setActiveLevel(l_activeLevel - 1);
					setActiveId(l_activeId);
					setEndReached(false);
				} else {
					setAddresses((prev) => {
						let updatedAddresses = [...prev];
						updatedAddresses = updatedAddresses.slice(0, l_activeLevel + 1);
						updatedAddresses[l_activeLevel + 1] = [];
						return updatedAddresses;
					});
					setEndReached(true);
				}
			});
		} else {
			setEndReached(true);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollLeft = scrollContainerRef.current.scrollWidth;
		}
	}, [addresses]);

	const closeAndReset = () => {
		setShow(false);
		setAddresses([]);
		setActiveLevel(props.level);
		setActiveId(props.defaultId);
		setEndReached(false);
		setSelectedItems([]);
		onSelect.current = null;
	};

	const getTenantSettings = async (action = () => {}) => {
		await runAction('tenants', 'getSettings')
			.then((res) => {
				const l_rootLevel = decideRootLevel(
					res.licenseFeatures.hasGroupsLocation,
					res.licenseFeatures.hasCampusesLocations,
				);
				action(res, l_rootLevel);
				setSettings(res);

				setRootLevel(l_rootLevel);

				setActiveLevel(mapLevels(rootLevel));
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const decideRootLevel = (hasGroupsLocation = true, hasCampusesLocations) => {
		if (!hasGroupsLocation) {
			if (!hasCampusesLocations) {
				return 4;
			}
			return 3;
		}
		return 6;
	};

	const mapLevels = (level) => {
		return LEVELS.length - level - 1;
	};
	const initiateData = async (data) => {
		await getTenantSettings(async (allSettings, l_rootLevel) => {
			await loadAddresses(mapLevels(l_rootLevel), props.defaultId);

			if (data.selected) {
				const iterateChildren = async (node) => {
					setActiveLevel(mapLevels(node.level));

					if (baseSelectionOnly) {
						if (settings.baseAddressLevel >= node.level) {
							setSelectDisabled(false);
						} else {
							setSelectDisabled(true);
						}
					}

					setSelected(node);
					setSelectedItems((prev) => {
						let updatedItems = [...prev];
						updatedItems = updatedItems.slice(0, mapLevels(node.level) + 1);
						updatedItems[mapLevels(node.level) + 1] = node.id;
						return updatedItems;
					});
					if (node.level > 1) await loadAddresses(mapLevels(node.level - 1), node.id);

					if (node.child) {
						iterateChildren(node.child);
					}
				};
				iterateChildren(data.selected);
			}
		});
	};

	useImperativeHandle(ref, () => ({
		callAction: async (action, data, callback) => {
			switch (action) {
				case 'open':
					setShow(true);
					onSelect.current = callback;
					initiateData(data);
					if (data?.initiallySelectDisabled) {
						setSelectDisabled(true);
						setBaseSelectionOnly(true);
					}

					break;
				default:
					throw Error(`No action named ${action} for portal ${props.id}`);
			}
		},
	}));

	const AddressGroup = ({ title = '', items = [], onSelect = () => {}, selectedId = null, hideArrow = false }) => {
		return (
			<div className='flex flex-col border-r border-gray-300 flex-none w-44 ml-1 h-full pr-2 overflow-y-auto '>
				<h1 className='mb-4'>{title.length > 0 ? title.charAt(0).toUpperCase() + title.slice(1) : ''}</h1>
				{Array.isArray(items) &&
					items.map((item, index) => (
						<button
							key={index}
							onClick={() => onSelect(item)}
							className={` focus:bg-gray-300 text-left text-gray-800 font-jakarta font-semibold text-sm leading-tight py-2 px-2 rounded mb-2 flex justify-between items-center ${
								selectedId === item.id ? 'bg-gray-300' : 'bg-white hover:bg-gray-200'
							}`}
						>
							<span>{item.name}</span>
							{!hideArrow && <i className='ri-arrow-right-s-line'></i>}
						</button>
					))}
			</div>
		);
	};

	const SecondaryButton = ({ onClick }) => {
		return (
			<button
				onClick={onClick}
				className=' w-10 flex items-center justify-center  rounded-lg border border-black hover:bg-black hover:bg-opacity-5 h-39 w-41 top-919 left-90 focus:ring-2 focus:ring-gray-400 active:bg-gray-400'
			>
				<i className='ri-map-2-line'></i>
			</button>
		);
	};

	return (
		<>
			{show &&
				createPortal(
					<div
						onClick={() => closeAndReset()}
						className='absolute z-50 top-0 bottom-0 right-0 left-0 bg-slate-800/50 flex justify-center items-center'
					>
						<div
							onClick={(e) => e.stopPropagation()}
							className='relative flex flex-col h-3/4 w-4/5 sm:w-3/4 md:w-2/3 lg:w-1/2 rounded-lg bg-white px-4 pt-4 '
						>
							<div className='flex flex-row  justify-between py-2 mb-2'>
								<div className='font-medium'>
									<i className='ri-hotel-line'></i>
									<span className='pl-1'>{translate('addressSelect')}</span>
								</div>
								<button onClick={closeAndReset} className='pr-2'>
									<i className='ri-close-line'></i>
								</button>
							</div>
							{loading && <Loading status={loading} />}

							<div
								className='flex overflow-x-scroll h-full w-full border-b border-gray-300'
								ref={scrollContainerRef}
							>
								{addresses.map((address, index) => {
									return address ? (
										<AddressGroup
											key={index}
											hideArrow={
												(index === addresses.length - 1 && endReached) ||
												mapLevels(settings.baseAddressLevel) + 1 === index
											}
											selectedId={selectedItems[index]}
											title={LEVELS[index]}
											items={address}
											onSelect={(item) => selectAddress(item)}
										/>
									) : (
										<></>
									);
								})}
							</div>

							<div className='flex flex-row-reverse justify-between pt-5 pb-5'>
								<Button
									onClick={() => {
										if (onSelect.current && selected)
											onSelect.current({
												selected,
												formatted: formatAddress(),
											}); // This calls the callback for the selected item
										closeAndReset();
									}}
									disabled={selectDisabled}
									className='px-3 py-2 text-xs'
								>
									{translate('select')}
								</Button>
								<SecondaryButton
									onClick={() => {
										console.log('Will display map here');
									}}
								/>
							</div>
						</div>
					</div>,
					document.body,
				)}
		</>
	);
});

export default AddressSelectorPortal;
