import { Button } from 'components/ui/Input';
import { useCrud, useTranslations } from 'hooks';
import TaskService from 'modules/tasks/pages/Tasks/pages/Tasks/services';
import { useEffect, useRef, useState } from 'react';
import ReturnTaskTable from './Table';

const ReturnTask = ({ item, isVisible, onReturnBack = () => {}, onClose = () => {}, onToTaskClick = () => {} }) => {
	const taskId = item.id;
	const service = new TaskService();
	const { translate } = useTranslations();

	const [isLoading, setIsLoading] = useState(false);
	const [queryString, setQueryString] = useState('page=1&pageSize=10');
	const [data, setData] = useState({ data: [], meta: {} });
	const [forwardTaskLocation, setForwardTaskLocation] = useState(null);

	const returnDialogRef = useRef(null);

	const { getAll } = useCrud({
		getAll: async (queryString) => await service.getConsecutiveTasks(queryString, taskId),
		getOne: null,
	});

	const fetchAndLoad = async (l_queryString = null, callback = () => {}) => {
		setIsLoading(true);
		try {
			const res = await getAll(l_queryString || queryString);

			callback(res?.data);

			setIsLoading(false);
			setData(res);
		} catch (error) {
			console.error('Error on Get All request: ', error);
			isLoading(false);
		}
	};

	useEffect(() => {
		if (isVisible) {
			if (!item.patient) {
				onReturnBack();
				return;
			}
			fetchAndLoad(null, (data) => {
				if (data.length === 0) {
					onReturnBack();
				} else {
					returnDialogRef.current.showModal();
				}
			});
		} else {
			returnDialogRef.current.close();
		}
	}, [isVisible]);

	const closeHandler = () => {
		returnDialogRef.current.close();
		onClose();
	};

	return (
		<dialog ref={returnDialogRef} className='rounded-lg overflow-y-visible'>
			<div className='flex flex-col  px-5 pt-5 pb-5 bg-gray-50'>
				<div className='flex flex-row justify-between items-center w-auto'>
					<div className='uppercase text-lg text-gray-500'>{translate('returnTask')}</div>
					<i className='ri-close-line cursor-pointer text-md text-gray-500' onClick={closeHandler}></i>
				</div>
				<div className='border-b-2 border-gray-200 mt-2 mb-5'></div>

				<div>
					<div className='text-gray-500 text-sm'>{translate('thereIsAPlannedFollowUpTask')}:</div>
				</div>
				{isVisible && (
					<div className='pt-3 px-3'>
						<ReturnTaskTable
							data={data}
							isLoading={isLoading}
							fetchAndLoad={(queryString) => {
								setQueryString(queryString);
								fetchAndLoad(queryString);
							}}
							onItemSelect={(item) => {
								setForwardTaskLocation(item?.[0] || null);
							}}
						/>
					</div>
				)}
				<div className='pt-5 pb-5 flex flex-row justify-center items-center space-x-10'>
					<Button small onClick={onReturnBack} color='primary'>
						<i className={`ri-arrow-go-back-line mr-2`}></i>
						{translate('returnBack')}
					</Button>

					<Button
						small
						onClick={() => {
							onToTaskClick(forwardTaskLocation);
						}}
						disabled={forwardTaskLocation === null}
					>
						<i className={`ri-expand-right-line mr-2`}></i>
						{translate('forwardToNextLocation')}
					</Button>
				</div>
			</div>
		</dialog>
	);
};

export default ReturnTask;
