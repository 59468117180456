import usePermissions, { PERMISSION, RESOURCE } from 'hooks/usePermissions';

const PersonsPermissions = () => {
	const { hasPermission } = usePermissions();
	return {
		users: {
			users: {
				User: {
					Create: hasPermission(RESOURCE.User, PERMISSION.Create),
					View: hasPermission(RESOURCE.User, PERMISSION.View),
					Update: hasPermission(RESOURCE.User, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.User, PERMISSION.Delete),
					Export: hasPermission(RESOURCE.User, PERMISSION.Export),
					Impersonate: hasPermission(RESOURCE.User, PERMISSION.Impersonate),
				},

				UserProfile: {
					Create: hasPermission(RESOURCE.Profile, PERMISSION.Create),
					View: hasPermission(RESOURCE.Profile, PERMISSION.View),
					Update: hasPermission(RESOURCE.Profile, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.Profile, PERMISSION.Delete),
					Export: hasPermission(RESOURCE.Profile, PERMISSION.Export),
				},
			},
			teamsAndDepartments: {
				Team: {
					Create: hasPermission(RESOURCE.Team, PERMISSION.Create),
					View: hasPermission(RESOURCE.Team, PERMISSION.View),
					Update: hasPermission(RESOURCE.Team, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.Team, PERMISSION.Delete),
					Export: hasPermission(RESOURCE.Team, PERMISSION.Export),
				},
				Department: {
					Create: hasPermission(RESOURCE.Department, PERMISSION.Create),
					View: hasPermission(RESOURCE.Department, PERMISSION.View),
					Update: hasPermission(RESOURCE.Department, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.Department, PERMISSION.Delete),
					Export: hasPermission(RESOURCE.Department, PERMISSION.Export),
				},
			},
			settings: {
				General: {
					PermissionGroup: {
						Create: hasPermission(RESOURCE.Permission, PERMISSION.Create),
						View: hasPermission(RESOURCE.Permission, PERMISSION.View),
						Update: hasPermission(RESOURCE.Permission, PERMISSION.Create),
						Delete: hasPermission(RESOURCE.Permission, PERMISSION.Delete),
						AssignToUser: hasPermission(RESOURCE.Permission, PERMISSION.AssignToUser),
					},
					Exclusions: {
						Create: hasPermission(RESOURCE.Exclusion, PERMISSION.Create),
						View: hasPermission(RESOURCE.Exclusion, PERMISSION.View),
						Update: hasPermission(RESOURCE.Exclusion, PERMISSION.Create),
						Delete: hasPermission(RESOURCE.Exclusion, PERMISSION.Delete),
					},
				},
				SSO: {
					Setup: {
						ActiveDirectory: {
							View: hasPermission(RESOURCE.ActiveDirectory, PERMISSION.View),
							Update: hasPermission(RESOURCE.ActiveDirectory, PERMISSION.Create),
						},
						EntraId: {
							View: hasPermission(RESOURCE.EntraId, PERMISSION.View),
							Update: hasPermission(RESOURCE.EntraId, PERMISSION.Create),
						},
					},
					Configuration: {
						Create:
							hasPermission(RESOURCE.ActiveDirectory, PERMISSION.Create) ||
							hasPermission(RESOURCE.EntraId, PERMISSION.Create),
						View:
							hasPermission(RESOURCE.ActiveDirectory, PERMISSION.View) ||
							hasPermission(RESOURCE.EntraId, PERMISSION.View),
						Update:
							hasPermission(RESOURCE.ActiveDirectory, PERMISSION.Create) ||
							hasPermission(RESOURCE.EntraId, PERMISSION.Create),
						Delete:
							hasPermission(RESOURCE.ActiveDirectory, PERMISSION.Delete) ||
							hasPermission(RESOURCE.EntraId, PERMISSION.Delete),
						Sync:
							hasPermission(RESOURCE.ActiveDirectory, PERMISSION.Sync) ||
							hasPermission(RESOURCE.EntraId, PERMISSION.Sync),
					},
				},
			},
		},
		patients: {
			Patients: {
				View: hasPermission(RESOURCE.Patient, PERMISSION.View),
				Create: hasPermission(RESOURCE.Patient, PERMISSION.Create),
				Update: hasPermission(RESOURCE.Patient, PERMISSION.Create),
				Delete: hasPermission(RESOURCE.Patient, PERMISSION.Delete),
			},
		},
	};
};

export default PersonsPermissions;
