import Card from 'components/ui/Card';
import { Table } from 'components/ui/Table';
import { useAside, useCrud, useTranslations } from 'hooks';
import { useEffect, useRef, useState } from 'react';

import StorageHandler from 'core/utils/StorageHandler';
import { CrudOperations } from 'hoc';
import { runAction } from 'modules/utils';
import { getToday } from 'utils';
import Controls from '../components/TableControls';
import TaskTemplatePicker from '../components/TaskTemplatePicker/TaskTemplatePicker';
import TaskForm from '../forms/task.form';
import TaskModel from '../models';
import TaskService from '../services';
import ActionRow from './ActionRow';
import MultipleActionRow from './ActionRow/MultipleActionRow';

const AUTO_REFRESH_INTERVAL = 20000;
const DEFAULT_FILTERS = {
	PlannedDate: getToday(),
	Statuses: [1, 2, 4, 7],
	TaskGroupIds: undefined,
};

const Tables = ({ permissions, onControlUpdate = () => {}, onRefresh = () => {} }) => {
	const [data, setData] = useState({ data: [], meta: {} });
	const storageHandler = new StorageHandler();

	const { asideBuilder } = useAside();
	const [isLoading, setIsLoading] = useState(false);
	const [taskActions, setTaskActions] = useState([]);
	const [taskStatuses, setTaskStatuses] = useState([]);
	const [defaultFilters, setDefaultFilters] = useState(null);
	const [customFiltersActive, setCustomFiltersActive] = useState(false);

	const pollHandler = useRef(null);
	const queryString = useRef('PlannedDate=' + getToday());
	const bodyFilters = useRef({});

	const service = new TaskService();
	const model = new TaskModel();
	model.setExportable(permissions.Export);

	const { create, update, search, remove, postExport } = useCrud(service);
	const { translate } = useTranslations();

	const fetchAndLoad = async (l_queryString = null, l_bodyFilters = null) => {
		setIsLoading(true);
		let searchBody = l_bodyFilters ? l_bodyFilters : bodyFilters.current;

		search(l_queryString || queryString.current, searchBody).then((res) => {
			setIsLoading(false);
			setData(res);
		});
	};

	const fetchTaskStatuses = async () => {
		try {
			const res = await runAction('taskModules', 'getTaskStatuses');

			if (!res) return;
			const l_statuses = res.map((status) => {
				if (status.id === 5) return { value: status.id, label: translate(status.name, true) + ' ⮐' };
				else return { value: status.id, label: translate(status.name, true) };
			});

			setTaskStatuses(l_statuses);
		} catch (error) {
			console.error('Error on getting task statuses ', error);
		}
	};

	const getModules = async () => {
		try {
			const res = await runAction('taskModules', 'getTaskActions');

			setTaskActions(res);
		} catch (error) {
			console.error('Error on getting task actions ', error);
		}
	};

	const { openCreate, openEdit, openClone, openView } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: TaskForm,
		componentName: model.getModelName(),
		permissions: permissions,
		closeOnOutsideClick: false,
		hardSaveMessage: 'DuplicatedEntity',
		hardSaveVariable: 'createAnyWay',
		formWidth: 48,
	});

	const storeFilters = (filters) => {
		storageHandler.storeSchema('custom-task-filters', filters);
	};

	const fetchFilters = async () => {
		const filters = await storageHandler.getSchema('custom-task-filters');
		if (filters) {
			setDefaultFilters({ ...filters, PlannedDate: filters.PlannedDate || getToday() });
			bodyFilters.current = filters;
			setCustomFiltersActive(true);
		} else {
			setDefaultFilters(DEFAULT_FILTERS);
			bodyFilters.current = DEFAULT_FILTERS;
			setCustomFiltersActive(false);
		}
	};

	const resetDefaultFiltersHandle = async () => {
		await storageHandler.removeSchema('custom-task-filters');
		setCustomFiltersActive(false);
		storeFilters(DEFAULT_FILTERS);
		setDefaultFilters(DEFAULT_FILTERS);
		bodyFilters.current = DEFAULT_FILTERS;
		window.location.reload();
	};

	const controlsUpdateHandler = (data) => {
		onControlUpdate(data);
		const keyMap = {
			date: 'PlannedDate',
			statuses: 'Statuses',
			taskGroups: 'TaskGroupIds',
		};

		const newDefaultValues = {};

		Object.keys(data).forEach((key) => {
			newDefaultValues[keyMap[key]] = data[key];
		});
		setCustomFiltersActive(true);

		const withoutPlannedDate = { ...newDefaultValues, PlannedDate: undefined };
		storeFilters(withoutPlannedDate);
		setDefaultFilters(newDefaultValues);
	};

	const openPickDialog = (l_data = null) => {
		asideBuilder.setTitle(translate('newTask'));
		asideBuilder.setComponent(TaskTemplatePicker);
		asideBuilder.setOpen(true);
		asideBuilder.hideSaveButton(true);
		asideBuilder.setComponentKey('pickDialog-new-' + new Date());
		asideBuilder.setComponentProps({
			data: {},
			pickTaskTemplate: (taskTemplate, taskGroupId, l_taskTemplateLabel) => {
				asideBuilder.setIsLoading(false);
				asideBuilder.setOpen(false);
				asideBuilder.build();
				openCreate({
					data: {
						taskGroupId,
						taskTemplateId: taskTemplate,
						taskTemplateLabel: l_taskTemplateLabel,
					},
				});
			},
		});
		asideBuilder.build();
	};

	const startPolling = () => {
		if (!pollHandler.current) {
			pollHandler.current = setInterval(() => {
				fetchAndLoad(queryString.current, bodyFilters.current);
				onRefresh();
			}, AUTO_REFRESH_INTERVAL);
		}
	};

	const stopPolling = () => {
		if (pollHandler.current) {
			clearInterval(pollHandler.current);
			pollHandler.current = null;
		}
	};

	useEffect(() => {
		fetchFilters();
		fetchTaskStatuses();
		// fetchAndLoad();
		getModules();
	}, []);

	useEffect(() => {
		startPolling();
		return () => {
			stopPolling();
		};
	}, [pollHandler]);

	const handlePoling = (status = true) => {
		if (status) {
			startPolling();
		} else {
			stopPolling();
		}
	};
	if (!defaultFilters) return <></>;

	return (
		<Card
			className='mt-2'
			collapsible
			defaultOpen={true}
			header={model.getPluralModelName()}
			headerButtonLabel={permissions.Create && `+ ${translate('addTask')}`}
			headerButtonClick={openPickDialog}
		>
			<Table
				controls={
					defaultFilters && (
						<Controls
							statuses={taskStatuses}
							prevFilters={defaultFilters}
							onChange={(data) => controlsUpdateHandler(data)}
							onResetDefault={resetDefaultFiltersHandle}
							customFiltersActive={customFiltersActive}
						/>
					)
				}
				defaultFilters={defaultFilters}
				isLoading={isLoading}
				model={model}
				canRefresh
				onRefreshRequest={() => {
					fetchAndLoad(queryString.current, bodyFilters.current);
					onRefresh();
				}}
				onExportClick={async (qs) => {
					return await postExport(qs, {}, model.getPluralModelName());
				}}
				meta={data.meta}
				data={data.data?.map((item) => {
					return {
						...item,
					};
				})}
				hasRowActions={true}
				RowActionsComponent={(props) => (
					<ActionRow
						{...props}
						fetchAndLoad={fetchAndLoad}
						taskActions={taskActions}
						viewTask={(item) => {
							permissions.View &&
								openView({
									id: item.id,
									taskTemplateId: item.taskTemplate?.id,
									taskGroupId: item.taskGroup?.id,
									taskTemplateLabel: item.taskTemplate?.name,
									name: item.name,
								});
						}}
						editTask={(item) => {
							permissions.Update &&
								openEdit({
									id: item.id,
									taskTemplateId: item.taskTemplate?.id,
									taskGroupId: item.taskGroup?.id,
									taskTemplateLabel: item.taskTemplate?.name,
									name: item.name,
									getServiceMethodName: item?.getServiceMethodName || null,
									secondParamId: item?.secondParamId || null, //this is currently used for next location
								});
						}}
						cloneTask={(item) => {
							openClone({
								id: item.id,
								taskTemplateId: item.taskTemplate?.id,
								taskGroupId: item.taskGroup?.id,
								taskTemplateLabel: item.taskTemplate?.name,
								getServiceMethodName: item.getServiceMethodName,
								name: item.name,
							});
						}}
						modalsVisible={(s) => handlePoling(!s)}
					/>
				)}
				MultipleRowActionsComponent={(props) => (
					<MultipleActionRow {...props} taskActions={taskActions} fetchAndLoad={fetchAndLoad} />
				)}
				onTableRequestChange={(qs, postFilters) => {
					bodyFilters.current = postFilters;
					queryString.current = qs;
					fetchAndLoad(qs, postFilters);
				}}
			/>
		</Card>
	);
};
export default Tables;
