import { Input } from 'components/ui/Input';
import { subMonths, subWeeks } from 'date-fns';
import { useTranslations } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

const WeekRangeSelectorInput = ({ defaultFromDate, defaultToDate }) => {
	const { translate } = useTranslations();
	const [values, setValues] = useState([3, 5]);
	const [isCustom, setIsCustom] = useState(false);
	const [startDate, setStartDate] = useState(
		defaultFromDate
			? new Date(defaultFromDate).toISOString().split('T')[0]
			: subWeeks(new Date(), 2).toISOString().split('T')[0],
	);
	const [endDate, setEndDate] = useState(
		defaultToDate ? new Date(defaultToDate).toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
	);

	const options = useMemo(
		() => [
			{ label: translate('lastThreeMonths'), value: 0 },
			{ label: translate('lastMonth'), value: 1 },
			{ label: translate('threeWeeksAgo'), value: 2 },
			{ label: translate('twoWeeksAgo'), value: 3 },
			{ label: translate('lastWeek'), value: 4 },
			{ label: translate('today'), value: 5 },
		],
		[translate],
	);

	useEffect(() => {
		if (defaultFromDate && defaultToDate) {
			const fromRangeValue = findRangeValueFromDate(defaultFromDate);
			const toRangeValue = findRangeValueFromDate(defaultToDate);
			if (fromRangeValue === null || toRangeValue === null) {
				setIsCustom(true);
				return;
			}
			setValues([fromRangeValue, toRangeValue]);
		}
	}, [defaultFromDate, defaultToDate]);

	const findRangeValueFromDate = (date) => {
		const today = new Date();
		if (date === today.toISOString().split('T')[0]) return 5;
		if (date === subWeeks(today, 1).toISOString().split('T')[0]) return 4;
		if (date === subWeeks(today, 2).toISOString().split('T')[0]) return 3;
		if (date === subWeeks(today, 3).toISOString().split('T')[0]) return 2;
		if (date === subMonths(today, 1).toISOString().split('T')[0]) return 1;
		if (date === subMonths(today, 3).toISOString().split('T')[0]) return 0;
		return null;
	};

	const handleRangeChange = (values) => {
		setValues(values);
		setStartDate(getDateFromValue(values[0]));
		setEndDate(getDateFromValue(values[1]));
		setIsCustom(false);
	};

	const getDateFromValue = (value) => {
		const today = new Date();
		switch (value) {
			case 0:
				return subMonths(today, 3).toISOString().split('T')[0];
			case 1:
				return subMonths(today, 1).toISOString().split('T')[0];
			case 2:
				return subWeeks(today, 3).toISOString().split('T')[0];
			case 3:
				return subWeeks(today, 2).toISOString().split('T')[0];
			case 4:
				return subWeeks(today, 1).toISOString().split('T')[0];
			case 5:
			default:
				return today.toISOString().split('T')[0];
		}
	};

	const handleFromDateChange = (e) => {
		setStartDate(e.target.value);
		setIsCustom(true);
	};

	const handleToDateChange = (e) => {
		setEndDate(e.target.value);
		setIsCustom(true);
	};

	return (
		<div className='flex flex-col space-y-4'>
			<div className='relative w-full'>
				{isCustom ? (
					<div className='text-xs text-gray-500 text-center'>{translate('customRange')}</div>
				) : (
					<div className='flex flex-row justify-between'>
						<div className='text-xs text-gray-500'>{options[values[0]]?.label}</div>
						<div className='text-xs text-gray-500'>{options[values[1]]?.label}</div>
					</div>
				)}

				<div className={`relative w-full mt-6 ${isCustom ? 'opacity-30' : 'opacity-100'}`}>
					<RangeSlider
						min={0}
						max={options.length - 1}
						step={1}
						value={values}
						onInput={handleRangeChange}
						className='w-full h-1 custom-range-slider'
					/>
					<div className='absolute top-0 left-0 w-full h-full flex justify-between'>
						{options.map((option, index) => (
							<div key={index} className='relative'>
								<div className='w-3 h-3 -mt-1 bg-gray-300  rounded-full'></div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className='flex flex-row space-x-2'>
				<Input
					label='fromDate'
					defaultValue={defaultFromDate}
					name='fromDate'
					required
					type='date'
					className='h-8 -mt-0.5'
					value={startDate}
					onChange={handleFromDateChange}
				/>
				<Input
					label='toDate'
					defaultValue={defaultToDate}
					name='toDate'
					required
					type='date'
					className='h-8 -mt-0.5'
					value={endDate}
					onChange={handleToDateChange}
				/>
			</div>
		</div>
	);
};

export default WeekRangeSelectorInput;
