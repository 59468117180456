import { useImperativeHandle, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { forwardRef } from 'react';

const BREAKPOINTS = { lg: 1200, md: 996, sm: 768 };
const COLS = { lg: 12, md: 10, sm: 6 };

const ResponsiveGridLayout = WidthProvider(Responsive);
const Main = forwardRef(
	(
		{
			components,
			isResizable = false,
			isDraggable = false,
			onRemoveComponent = () => {},
			onResizeComponent = () => {},
			onDropComponent = () => {},
			isExportable = false,
		},
		ref,
	) => {
		const [trigger, setTrigger] = useState(false);
		const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');

		const layouts = {
			lg: components.map((comp) => {
				return { i: comp.key, ...comp.layouts.lg };
			}),
			md: components.map((comp) => {
				return { i: comp.key, ...comp.layouts.md };
			}),
			sm: components.map((comp) => {
				return { i: comp.key, ...comp.layouts.sm };
			}),
		};
		const triggerReRender = () => {
			setTrigger((prev) => !prev);
		};

		useImperativeHandle(ref, () => ({
			refresh: triggerReRender,
		}));

		const maxZIndexValue = Math.max(
			...components.map((comp) => comp.layouts[currentBreakpoint].y + comp.layouts[currentBreakpoint].h),
		);

		components = components.map((comp, index) => ({
			...comp,
			zIndex: Math.round(
				(maxZIndexValue - (comp.layouts[currentBreakpoint].y + comp.layouts[currentBreakpoint].h)) / 10,
			),
		}));

		return (
			<div>
				<ResponsiveGridLayout
					key={trigger}
					className='layout'
					layouts={layouts}
					breakpoints={BREAKPOINTS}
					cols={COLS}
					rowHeight={30}
					isResizable={isResizable}
					isDraggable={isDraggable}
					resizeHandles={['se', 'sw', 'ne', 'nw']}
					onBreakpointChange={(breakpoint) => {
						setCurrentBreakpoint(breakpoint);
					}}
					onResizeStop={(layout, oldItem, newItem) => {
						onResizeComponent(layout, oldItem, newItem, currentBreakpoint);
					}}
					onDragStop={(layout, oldItem, newItem) => {
						onDropComponent(layout, oldItem, newItem, currentBreakpoint);
					}}
				>
					{components.map((comp, index) => {
						if (comp.hidden) return null;
						const Component = comp.component;
						return (
							<Component
								key={comp.key}
								isDraggable={isDraggable}
								zIndex={comp.zIndex + 10}
								isExportable={isExportable}
								onRemove={() => onRemoveComponent(comp.key)}
								{...comp?.props}
							/>
						);
					})}
				</ResponsiveGridLayout>
			</div>
		);
	},
);
export default Main;
