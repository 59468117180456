import { Table } from 'components/ui/Table';

import ReturnTaskModel from './models';

const ReturnTaskTable = ({
	data = null,
	isLoading = false,
	onItemSelect = () => {},
	onTaskClick = () => {},
	fetchAndLoad = (queryString) => {},
}) => {
	const model = new ReturnTaskModel();

	return (
		<Table
			singleSelect={true}
			isLoading={isLoading}
			displayCheckBoxes={false}
			isFilterable={false}
			columnsCanBeModified={false}
			isExportable={false}
			model={model}
			meta={data.meta}
			onTableRequestChange={(queryString) => {
				fetchAndLoad(queryString);
			}}
			onItemSelect={onItemSelect}
			onRowClick={onTaskClick}
			data={data.data}
		/>
	);
};
export default ReturnTaskTable;
