import { localStorageKeys } from 'config';
import HomeService from '../../../../services/index';

const getSummary = async (item = null) => {
	const EXPIRATION_TIME = 1 * 60 * 1000; // 1 minutes in milliseconds
	const now = new Date().getTime();

	let summary = localStorage.getItem(localStorageKeys.HOME_SUMMARY_STORAGE_KEY);

	if (summary && JSON.parse(summary)) {
		summary = JSON.parse(summary);
		const { data, timestamp } = summary;

		if (now - timestamp <= EXPIRATION_TIME) {
			if (item) {
				return data[item];
			}
			return data;
		}
		localStorage.removeItem(localStorageKeys.HOME_SUMMARY_STORAGE_KEY);
	}

	if (localStorage.getItem(localStorageKeys.HOME_SUMMARY_STORAGE_KEY + '-loading')) {
		await new Promise((resolve) => setTimeout(resolve, 1000));
		summary = localStorage.getItem(localStorageKeys.HOME_SUMMARY_STORAGE_KEY);
		if (summary && JSON.parse(summary)) {
			summary = JSON.parse(summary);
			const { data, timestamp } = summary;

			if (now - timestamp <= EXPIRATION_TIME) {
				if (item) {
					return data[item];
				}
				return data;
			}
		}
	}
	localStorage.setItem(localStorageKeys.HOME_SUMMARY_STORAGE_KEY + '-loading', true);

	const service = new HomeService();
	const result = await service.getSummary();
	const data = result?.data?.[0] || {};

	localStorage.setItem(localStorageKeys.HOME_SUMMARY_STORAGE_KEY, JSON.stringify({ data, timestamp: now }));
	localStorage.removeItem(localStorageKeys.HOME_SUMMARY_STORAGE_KEY + '-loading');

	if (item) {
		return data[item];
	}
	return data;
};

const utils = {
	getSummary,
};

export default utils;
