import localforage from 'localforage';
import constants from './constants';

export const SELECT_ALL_VALUE = 1;
export const SEARCH_TERM_MIN_LENGTH = 3;

export const PERMISSION_LICENSE_FEATURES = [
	{
		licenseKey: 'hasAssets',
		permissionKey: 'Asset',
	},
	{
		licenseKey: 'hasActiveDirectory',
		permissionKey: 'ActiveDirectory',
	},
	{
		licenseKey: 'hasEntraId',
		permissionKey: 'EntraId',
	},
];

const configs = {
	displayWarningsForUnFoundTranslations: false,
	displayWarningForNotPresentTranslations: false,
	defaultMaxTaskGroups: 10,
	localforageConfig: {
		driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
		name: constants.STORAGE_KEY + '-localforage',
		storeName: constants.STORAGE_KEY + '-localforage-store',
		description: 'LocalForage database for ' + constants.STORAGE_KEY,
	},
	SELECT_ALL_VALUE,
	SEARCH_TERM_MIN_LENGTH,
	PERMISSION_LICENSE_FEATURES,
};

export default configs;
