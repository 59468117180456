import AbstractService from 'services/Service';
const endpoint = 'taskgroups/templates';

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}


	getAll() {
		throw new Error('Method not allowed.');
	}

	getOne() {
		throw new Error('Method not allowed.');
	}

	getMultiple() {
		throw new Error('Method not allowed.');
	}

	create() {
		throw new Error('Method not allowed.');
	}

	update() {
		throw new Error('Method not allowed.');
	}

	remove() {
		throw new Error('Method not allowed.');
	}

	getExport() {
		throw new Error('Method not allowed.');
	}
}

export default Service;
