import { Button, CheckBox } from 'components/ui/Input';

import { useTranslations } from 'hooks';

const Settings = ({
	display = false,
	showLegends = true,
	onShowLegendsClick = () => {},
	onRemove = () => {},
	onCancel = () => {},
}) => {
	const { translate } = useTranslations();

	return !display ? (
		<></>
	) : (
		<div className='absolute top-0 right-0 mt-12 mr-4 w-64 h-auto bg-white shadow-lg rounded-lg p-4 z-50'>
			<div className='text-gray-500 pb-2 text-sm '>{translate('settings')}</div>

			<CheckBox label={'showLegends'} selected={showLegends} onChange={onShowLegendsClick} />

			<div className='border-t border-gray-200 my-2'></div>
			<div className='text-xs text-gray-400 '>{translate('youCanAddThisChartLatterOnAddChartSection')}</div>
			<div className='flex justify-between pt-3'>
				<Button
					color='transparent'
					small
					onClick={() => {
						onCancel();
					}}
				>
					{translate('cancel')}
				</Button>
				<div
					onClick={onRemove}
					className='cursor-pointer text-xs flex justify-center items-center tracking-wide text-red-500 border p-1 rounded-md border-red-200 px-2 opacity-50 hover:opacity-100'
				>
					{translate('removeChart')}
				</div>
			</div>
		</div>
	);
};

export default Settings;
