import { withSuspense } from 'hoc';
import { useToolbar } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import StorageHandler from 'core/utils/StorageHandler';
import ControlBar from './components/ControlBar';
import Main from './components/Main';
import componentMap from './config/componentMap';
import {
	applyListComponentChange,
	getChangedComponentsByType,
	getComponentsWithoutComponent,
	mapComponentWithKey,
} from './utils/ComponentHandlers';
import ControlHandlers from './utils/ControlHandlers';

const DashboardPage = () => {
	const { crumbsBuilder } = useToolbar();
	const storageHandler = new StorageHandler();
	const { permissionsMap } = useOutletContext();

	const [components, setComponents] = useState(componentMap);
	const [tempResizeComponents, setTempResizeComponents] = useState(null);
	const [tempMoveComponents, setTempMoveComponents] = useState(null);
	const [isResizable, setIsResizable] = useState(false);
	const [isDraggable, setIsDraggable] = useState(false);

	const mainComponentRef = useRef(null);

	const { openCounterManager, closeCounterManager, openChartManager, closeChartManager } = ControlHandlers({
		counters: components.filter((c) => c.type === 'counter'),
		charts: components.filter((c) => c.type === 'chart'),
		onApplyCounterChanges: (counters) => applyCounterChangesHandler(counters),
		onApplyChartChanges: (charts) => applyChartChangesHandler(charts),
	});

	/** HANDLERS */

	const applyCounterChangesHandler = (counters) => {
		const newComponents = getChangedComponentsByType(components, counters, 'counter');
		setComponents(newComponents);
		closeCounterManager();
		mainComponentRef.current.refresh();

		storageHandler.storeSchema('custom-dashboard-config', getComponentsWithoutComponent(newComponents));
	};

	const applyChartChangesHandler = (charts) => {
		const newComponents = getChangedComponentsByType(components, charts, 'chart');
		setComponents(newComponents);
		closeChartManager();
		mainComponentRef.current.refresh();

		storageHandler.storeSchema('custom-dashboard-config', getComponentsWithoutComponent(newComponents));
	};

	const removeComponentHandler = (key) => {
		const newComponents = components.map((c) => {
			if (c.key === key) return { ...c, hidden: true };
			return c;
		});
		setComponents(newComponents);
		mainComponentRef.current.refresh();

		storageHandler.storeSchema('custom-dashboard-config', getComponentsWithoutComponent(newComponents));
	};

	const handleResizeClick = () => {
		if (!isResizable) {
			setIsResizable(true);
		} else {
			setIsResizable(false);
			if (!tempResizeComponents) return;

			setComponents(tempResizeComponents);
			const dataToStore = getComponentsWithoutComponent(tempResizeComponents);
			storageHandler.storeSchema('custom-dashboard-config', dataToStore);
			setTempResizeComponents(null);
			mainComponentRef.current.refresh();
		}
	};

	const handleResize = (layout, oldItem, newItem, breakpoint) => {
		setTempResizeComponents(applyListComponentChange(components, layout, breakpoint));
	};

	const handleMoveClick = () => {
		if (!isDraggable) {
			setIsDraggable(true);
		} else {
			setIsDraggable(false);
			if (!tempMoveComponents) return;

			setComponents(tempMoveComponents);
			const dataToStore = tempMoveComponents.map((c) => ({ ...c, component: undefined }));
			storageHandler.storeSchema('custom-dashboard-config', dataToStore);
			setTempMoveComponents(null);
			mainComponentRef.current.refresh();
		}
	};

	const handleMove = (layout, oldItem, newItem, breakpoint) => {
		setTempMoveComponents(applyListComponentChange(components, layout, breakpoint));
	};

	const handleManageCounter = () => {
		openCounterManager();
	};

	const handleManageChart = () => {
		openChartManager();
	};

	const handleCancel = () => {
		setIsResizable(false);
		setIsDraggable(false);
		setTempResizeComponents(null);
		setTempMoveComponents(null);
	};

	const refreshHandler = () => {
		mainComponentRef.current.refresh();
	};

	const resetDefaultHandler = async () => {
		await storageHandler.removeSchema('custom-dashboard-config');
		window.location.reload();
	};

	/** EFFECTS */

	useEffect(() => {
		crumbsBuilder.addPath('/tasks', 'tasks');
		crumbsBuilder.addCurrent('dashboard');
		crumbsBuilder.build();
	}, []); // Add crumbsBuilder to the dependency array

	useEffect(() => {
		storageHandler
			.getSchema('custom-dashboard-config')
			.then((data) => {
				if (data) {
					setComponents(mapComponentWithKey(data, componentMap));
				}
			})
			.finally(() => {
				mainComponentRef.current?.refresh();
			});
	}, []);

	if (!permissionsMap.View) return null;

	return (
		<div className='bg-gray-100 min-h-screen pb-5'>
			<Main
				ref={mainComponentRef}
				isDraggable={isDraggable}
				isResizable={isResizable}
				components={components}
				isExportable={permissionsMap.Export}
				onRemoveComponent={removeComponentHandler}
				onResizeComponent={handleResize}
				onDropComponent={handleMove}
			/>
			<ControlBar
				cancelVisible={isResizable || isDraggable}
				onResizeClick={handleResizeClick}
				isResizing={isResizable}
				onMoveClick={handleMoveClick}
				isMoving={isDraggable}
				onRefresh={refreshHandler}
				onManageCounter={handleManageCounter}
				onManageChart={handleManageChart}
				onCancel={handleCancel}
				onResetDefault={resetDefaultHandler}
			/>
		</div>
	);
};

export default withSuspense(DashboardPage);
