import { useTranslations } from 'hooks';
import ControlButton from './ControlButton';

const MoveButton = ({ onMove = () => {}, isMoving = false }) => {
	const { translate } = useTranslations();

	return (
		<ControlButton
			name={isMoving ? translate('apply') : translate('move')}
			colorClass={isMoving ? 'text-red-400 hover:text-red-700' : undefined}
			icon='ri-drag-move-2-line'
			onClick={onMove}
		/>
	);
};
export default MoveButton;
