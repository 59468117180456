import { Button } from 'components/ui/Input';
import { useTranslations } from 'hooks';

const ControlButtons = ({ onReset = () => {}, onCancel = () => {}, onSubmitButtonClick = () => {} }) => {
	const { translate } = useTranslations();

	return (
		<div className='flex flex-row items-center justify-between pt-3'>
			<div>
				<Button
					color='danger'
					small
					onClick={() => {
						onReset();
					}}
				>
					{translate('resetFilters')}
				</Button>
			</div>
			<div className='space-x-2  flex justify-end'>
				<Button
					color='transparent'
					small
					onClick={() => {
						onCancel();
					}}
				>
					{translate('cancel')}
				</Button>
				<Button
					type='submit'
					small
					onClick={() => {
						onSubmitButtonClick();
					}}
				>
					{translate('applyFilters')}
				</Button>
			</div>
		</div>
	);
};
export default ControlButtons;
