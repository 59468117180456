export const mapComponentWithKey = (components, componentMap) => {
	return components.map((c) => {
		const component = componentMap.find((cm) => cm.key === c.key);
		if (!component) return c;
		return { ...component, ...c };
	});
};

export const getComponentsWithoutComponent = (components) => {
	return components?.map((c) => ({ ...c, component: undefined }));
};

export const getChangedComponentsByType = (components, changedComponents, type) => {
	return components?.map((c) => {
		if (c.type !== type) return c;
		const newComponent = changedComponents?.find((chCo) => chCo.key === c.key);
		if (!newComponent) return c;
		return { ...c, hidden: newComponent.hidden };
	});
};

export const applySingleComponentChange = (components, componentToChange, breakpoint) => {
	return components.map((c) => {
		if (c.key === componentToChange.i) {
			return { ...c, layouts: { ...c.layouts, [breakpoint]: mapComponentItem(componentToChange) } };
		}
		return c;
	});
};

export const applyListComponentChange = (components, componentList, breakpoint) => {
	return components.map((c) => {
		//find the layout that matches the key
		const layout = componentList.find((l) => l.i === c.key);
		if (!layout) return c;

		return { ...c, layouts: { ...c.layouts, [breakpoint]: mapComponentItem(layout) } };
	});
};

const mapComponentItem = (component) => {
	return {
		x: component.x,
		y: component.y,
		w: component.w,
		h: component.h,
		minW: component.minW,
		maxW: component.maxW,
		minH: component.minH,
		maxH: component.maxH,
	};
};
