import { PermissionsContext } from 'contexts/PermissionsManager';
import { useCallback, useContext } from 'react';

export const PERMISSION = {
	View: 1,
	Create: 2,
	Delete: 4,
	AssignToUser: 8,
	Sync: 8,
	Import: 8,
	Export: 16,
	Impersonate: 32,
	Allocate: 32,
	TasksList: 1,
	OnlineEmployees: 8,
	TeamOverview: 16,
	ShowQrCode: 8,
	ShowDebug: 16,
	List: 8,
	CreateAdHoc: 16,
	Execute: 32,
};

/**
 * Resource FE to BE Mapping
 * In case of change in backend, we can just rename it here
 */

export const RESOURCE = {
	ActiveDirectory: 'ActiveDirectory',
	EntraId: 'EntraId',
	Address: 'Address',
	Asset: 'Asset',
	CustomField: 'CustomField',
	ComplexTaskAction: 'ComplexTaskAction',
	Dashboard: 'Dashboard',
	DefaultSetting: 'DefaultSetting',
	Department: 'Department',
	Dispatcher: 'Dispatcher',
	Documentations: 'Documentations',
	HL7: 'HL7',
	Language: 'Language',
	Message: 'Message',
	Notification: 'Notification',
	Modules: 'Modules',
	Patient: 'Patient',
	Permission: 'Permission',
	Profile: 'Profile',
	Rule: 'Rule',
	TaskGroup: 'TaskGroup',
	TaskItem: 'TaskItem',
	TaskType: 'TaskType',
	Team: 'Team',
	Template: 'Template',
	TenantSettings: 'TenantSettings',
	Urgency: 'Urgency',
	User: 'User',
	Exclusion: 'Exclusion',
	AccessDashboardPanel: 'AccessDashboardPanel',
	TaskAutomationRule: 'TaskAutomationRule',
	SuperAdmin: 'SuperAdmin',
	PorterConfig: 'PorterConfig',
	PorterType: 'PorterType',
};

/**
 * Usage:
 * import usePermissions, {RESOURCE, PERMISSION} from "hools/usePermissions";
 * ...
 * const { hasPermission } = usePermissions();
 * if ( hasPermission(RESOURCE.User, PERMISSION.View) ) {
 *      Grant view data
 * }
 *
 */
const usePermissions = () => {
	const permissions = useContext(PermissionsContext);

	const hasPermission = useCallback(
		(resource, permission) => {
			if (!permissions || permission === null) return false;

			const licenseFeatures = permissions?.licenseFeatures;

			if (
				Object.keys(permissions).length > 0 &&
				permissions?.SuperAdmin !== null &&
				permissions?.SuperAdmin !== undefined
			) {
				const licensePermission = Object.keys(licenseFeatures).find((key) => key === resource);
				if (licensePermission === undefined) return true;

				if (licenseFeatures[licensePermission] === true) return true;

				return false;
			}

			if (permissions?.[resource] === undefined) return false;
			else {
				return (permissions[resource] & permission) === permission;
			}
		},
		[permissions],
	);

	const checkNestedPermissions = (permissions) => {
		for (let key in permissions) {
			if (typeof permissions[key] === 'boolean' && permissions[key]) {
				return true;
			} else if (typeof permissions[key] === 'object') {
				if (checkNestedPermissions(permissions[key])) {
					return true;
				}
			}
		}
		return false;
	};

	return {
		hasPermission,
		checkNestedPermissions,
	};
};

export default usePermissions;
