import { Button, Input, SearchSelect } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import TaskGroupService from 'modules/tasks/pages/Manage/services';
import { useEffect, useState } from 'react';
import { getToday } from 'utils';

const TableControls = ({
	prevFilters = {},
	statuses,
	onChange = () => {},
	customFiltersActive = false,
	onResetDefault = () => {},
}) => {
	const { translate } = useTranslations();

	const taskGroupService = new TaskGroupService();

	const fetchTaskGroups = async () => {
		taskGroupService.getOptionsList().then((res) => {
			setTaskGroupOptions(res.data);
		});
	};

	const today = getToday();
	const [taskGroupOptions, setTaskGroupOptions] = useState([]);

	const [actualFilters, setActualFilters] = useState({
		date: prevFilters?.PlannedDate || today,
		statuses: prevFilters?.Statuses || [],
		taskGroups: prevFilters?.TaskGroupIds || null,
	});

	useEffect(() => {
		setActualFilters({
			date: prevFilters?.PlannedDate || today,
			statuses: prevFilters?.Statuses || [],
			taskGroups: prevFilters?.TaskGroupIds || null,
		});
	}, [prevFilters]);

	const handleChange = (filters) => {
		setActualFilters(filters);
		onChange(filters);
	};

	useEffect(() => {
		fetchTaskGroups();
	}, []);

	const onTodayClick = () => {
		handleChange({ ...actualFilters, date: today });
	};

	const onDateChange = (e) => {
		handleChange({ ...actualFilters, date: e.target.value });
	};

	const onNextDayClick = () => {
		const nextDay = new Date(actualFilters.date);
		nextDay.setDate(nextDay.getDate() + 1);

		handleChange({ ...actualFilters, date: nextDay.toISOString().split('T')[0] });
	};

	const onPrevDayClick = () => {
		const prevDay = new Date(actualFilters.date);
		prevDay.setDate(prevDay.getDate() - 1);

		handleChange({ ...actualFilters, date: prevDay.toISOString().split('T')[0] });
	};

	return (
		<div className='flex flex-col lg:flex-row pl-2 space-x-2 pb-3 items-center relative'>
			<div className='w-full lg:w-1/4 '>
				<Input type='date' onChange={(e) => onDateChange(e)} value={actualFilters.date} />
			</div>
			<div className='flex flex-row space-x-2 lg:space-x-0 my-2 lg-my-0'>
				<div>
					<Button onClick={() => onTodayClick()} color={'transparent'}>
						{translate('today')}
					</Button>
				</div>
				<div>
					<Button onClick={() => onPrevDayClick()} iconOnly={true} color={'transparent'}>
						<i className='ri-arrow-left-s-line'></i>
					</Button>
				</div>
				<div>
					<Button onClick={() => onNextDayClick()} iconOnly={true} color={'transparent'}>
						<i className='ri-arrow-right-s-line'></i>
					</Button>
				</div>
			</div>

			<div className='w-full lg:w-1/4 pt-3 lg:pt-0 '>
				<SearchSelect
					multiple
					options={statuses}
					placeholder={translate('status')}
					label='statuses'
					containerClassName='-mt-4'
					defaultSelected={actualFilters.statuses}
					allItemsAreSelectedMessage='allStatusesAreSelected'
					onChange={(option) => {
						const selected = option?.map((item) => item.value);
						handleChange({ ...actualFilters, statuses: selected });
					}}
				/>
			</div>

			<div className='w-full lg:w-1/4 pt-7 lg:pt-0 '>
				<SearchSelect
					multiple
					service={taskGroupService}
					name='taskGroupIds'
					placeholder={translate('taskGroups')}
					containerClassName='-mt-4'
					label='taskGroups'
					allItemsAreSelectedMessage='allTaskGroupsAreSelected'
					defaultSelected={actualFilters?.taskGroups || taskGroupOptions?.map((item) => item.value)}
					onChange={(value) => {
						const selected = value?.map((item) => item.value);
						handleChange({ ...actualFilters, taskGroups: selected });
					}}
				/>
			</div>
			{customFiltersActive && (
				<div className='relative lg:absolute lg:-right-80 mb-5 '>
					<button
						className='text-xs justify-center items-center flex  text-gray-500 hover:text-gray-800'
						onClick={onResetDefault}
					>
						<i className='ri-arrow-go-back-line text-lg'></i>
						<span className='pl-1'>{translate('resetDefault')}</span>
					</button>
				</div>
			)}
		</div>
	);
};

export default TableControls;
