import { SearchSelect } from 'components/ui/Input';
import { useTranslations } from 'hooks';

const DayOfWeeksInput = ({ defaultValue, name = 'days', label = 'days', allItemsAreSelectedMessage = 'everyday' }) => {
	const { translate } = useTranslations();

	const daysOfWeek = [
		{ value: 0, label: translate('sunday') },
		{ value: 1, label: translate('monday') },
		{ value: 2, label: translate('tuesday') },
		{ value: 3, label: translate('wednesday') },
		{ value: 4, label: translate('thursday') },
		{ value: 5, label: translate('friday') },
		{ value: 6, label: translate('saturday') },
	];

	return (
		<div>
			<SearchSelect
				allItemsAreSelectedMessage={allItemsAreSelectedMessage}
				className='h-8 -mt-0.5'
				label={label}
				multiple
				name={name}
				defaultValue={defaultValue}
				options={daysOfWeek}
			/>
		</div>
	);
};
export default DayOfWeeksInput;
