import { renderToString } from 'react-dom/server';
import { TruncateString } from 'utils';
import AddressLocationRender from './components/AddressLocationRender';
const AddressRenderer = ({ value, belowLevel = 2, truncateLength = 5 }) => {
	let address = value?.child;
	const addressList = [];
	while (address) {
		addressList.push(address);
		address = address?.child;
	}

	if (belowLevel) {
		const temp = addressList.filter((a) => a.level <= belowLevel);
		addressList.length = 0;
		addressList.push(...temp);
	}

	return (
		<div
			data-tooltip-variant='light'
			data-tooltip-html={renderToString(AddressLocationRender({ address: value }))}
			data-tooltip-id={`table-tooltip`}
		>
			{addressList.map((address, index) => (
				<div key={index} className='flex items-center'>
					<span className='text-gray-500 whitespace-nowrap '>
						{TruncateString(address.name, truncateLength)}
					</span>
					{index !== addressList.length - 1 && <span className='text-gray-500 mx-2'>/</span>}
				</div>
			))}
		</div>
	);
};

export default AddressRenderer;
