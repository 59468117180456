import { ReSelect } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import toast from 'react-hot-toast';
const Item = ({
	department,
	index,
	options = [],
	disabled = false,
	onRemove = () => {},
	open = true,
	setOpen = () => {},
	inputPrefix = '',
	isView = false,
	onUpdateDepartment = () => {},
}) => {
	const { translate } = useTranslations();

	const getLabelFromOptions = (id) => {
		const departmentOption = options.find((option) => option.value === id);
		return departmentOption?.label;
	};

	const removeToDepartment = (index) => {
		//at least one department should be set
		if (department?.departmentsTo.length === 1) {
			toast(translate('atLeastOneDepartmentShouldBeSet'), {
				duration: 2000,
				icon: '❗️',
			});
			return;
		}

		department.departmentsTo.splice(index, 1);
		onUpdateDepartment(department);
	};

	const setAllToDepartments = () => {
		department.departmentsTo = options.map((opt) => ({ id: opt.value, name: opt.label }));
		onUpdateDepartment(department);

		// setToDepartments(options);
	};

	const handleOnSelect = (selected) => {
		const departmentToAdd = options.filter((item) => selected === item.value)[0];

		if (department.departmentTo?.some((department) => department.value === departmentToAdd.value)) {
			return;
		}

		//if no key of departmentsTo, add it and push

		if (!department.departmentsTo) {
			department.departmentsTo = [];
		}

		const tDep = { id: departmentToAdd.value, name: departmentToAdd.label };

		department.departmentsTo.push(tDep);
		onUpdateDepartment(department);
	};

	return (
		<div
			className={`bg-gray-50 border relative border-gray-300  text-gray-900 text-sm rounded-lg block w-full p-2.5 my-1`}
		>
			<input type='hidden' name={`${inputPrefix}departments[${index}].departmentId`} value={department?.value} />
			{department?.departmentsTo?.map((departmentTo, depIndex) => (
				<input
					type='hidden'
					name={`${inputPrefix}departments[${index}].departmentToIds[${depIndex}].id`}
					value={departmentTo?.id}
				/>
			))}
			<div key={index}>
				<div className=' flex justify-between items-center cursor-pointer'>
					<div onClick={() => setOpen(!open)} className='w-4/5'>
						{department.label}
					</div>
					{!isView && (
						<div>
							<button
								className='px-1 py-1'
								disabled={disabled}
								type='button'
								onClick={(e) => {
									onRemove(index);
								}}
							>
								<i className='ri-delete-bin-line'></i>
							</button>
						</div>
					)}
				</div>

				{open && (
					<div className={` pb-1 `}>
						<div className='pl-2 pt-2 pb-2'>
							{department?.departmentsTo?.map((departmentTo, depIndex) => (
								<div key={depIndex} className='flex items-center justify-between pt-1'>
									<div className='bg-gray-200 flex justify-between w-full text-gray-600 px-3 py-0.5 rounded-md'>
										<div>{getLabelFromOptions(departmentTo.id)}</div>
										<div>
											{!isView && (
												<button
													disabled={disabled}
													type='button'
													onClick={() => removeToDepartment(depIndex)}
												>
													<i class='ri-close-circle-line'></i>
												</button>
											)}
										</div>
									</div>
								</div>
							))}
							{!isView && (
								<ReSelect
									className='mt-2'
									small={true}
									disabled={disabled}
									options={options.filter((option) =>
										department?.departmentsTo
											? department?.departmentsTo?.every(
													(department) => department.id !== option.value,
											  )
											: true,
									)}
									placeholder={translate('selectDepartment')}
									onSelect={(selected) => {
										handleOnSelect(selected);
									}}
								/>
							)}
						</div>
						{!isView && department?.departmentsTo && department?.departmentsTo?.length < 2 && (
							<div
								className=' pl-4 pb-2  text-red-700 opacity-30 hover:opacity-80 cursor-pointer text-xs '
								onClick={setAllToDepartments}
							>
								<i class='ri-play-list-add-fill pr-2'></i>
								{translate('selectAsDefaultAllPossibleDepartments')}
							</div>
						)}
					</div>
				)}

				<div
					className={`flex absolute w-full bottom-1 justify-center items-center cursor-pointer -my-1 rounded-md ${
						open ? 'hover:bg-gray-100' : 'bg-none'
					}  transition-colors duration-200 ease-in-out`}
					onClick={() => setOpen(!open)}
				>
					<i
						className={`ri-arrow-down-s-line text-gray-500 transition-transform duration-200  ease-in-out ${
							open ? 'transform rotate-180' : ''
						}`}
					></i>
				</div>
			</div>
		</div>
	);
};

export default Item;
