import { useTranslations } from 'hooks';

const TextDivider = ({ text, className = '' }) => {
	const { translate } = useTranslations();
	return (
		<div className={`flex items-center justify-center my-4  ${className}`}>
			<div className='flex-grow border-t border-gray-300'></div>
			<span className='mx-2 text-gray-400 text-xs text-center'>{translate(text)}</span>
			<div className='flex-grow border-t border-gray-300'></div>
		</div>
	);
};
export default TextDivider;
