import { Input, ReSelect } from 'components/ui/Input';
import { useRef } from 'react';

import { useForm, useTranslations } from 'hooks';
import TeamService from 'modules/persons/pages/Users/pages/TeamsAndDepartments/Teams/services';
import { ControlButtons, DayOfWeeksInput, TextDivider, WeekRangeSelectorInput } from '../components/FilterComponents';

import { convertStringArraysToNumbers } from '../utils/chartHelper';

const Filters = ({ data = null, display = false, onCancel = () => {}, onSubmit = () => {} }) => {
	const { translate } = useTranslations();
	const { getData } = useForm();
	const myForm = useRef(null);

	const teamService = new TeamService();

	const handleFormSubmit = (e) => {
		e.preventDefault();

		const data = getData(myForm.current);
		const mappedData = mapFilterData(data);

		onSubmit(mappedData);
	};

	const mapFilterData = (data) => {
		const cData = convertStringArraysToNumbers(data);

		return {
			days: cData.days,
			teamId: cData.teamId ? parseInt(cData.teamId) : undefined,
			intervalMinutes: parseInt(cData.intervalMinutes),
			fromDate: data.fromDate === '' ? undefined : data.fromDate,
			toDate: data.toDate === '' ? undefined : data.toDate,
			startTime: data.startTime === '' ? undefined : data.startTime,
			endTime: data.endTime === '' ? undefined : data.endTime,
		};
	};

	const resetHandler = () => {
		onSubmit(null);
		onCancel();
	};

	return !display ? (
		<></>
	) : (
		<div className='absolute top-0 right-0 mt-12 mr-4 w-96 h-auto bg-white shadow-lg rounded-lg p-4 z-50'>
			<form onSubmit={handleFormSubmit} ref={myForm}>
				<WeekRangeSelectorInput defaultFromDate={data?.fromDate} defaultToDate={data?.toDate} />

				<TextDivider text={'momentBased'} />
				<div className='space-y-2'>
					<div className='flex flex-row space-x-2'>
						<Input
							label='startTime'
							required
							defaultValue={data?.startTime}
							name='startTime'
							type='time'
							className='h-8 -mt-0.5'
						/>
						<Input
							required
							label='endTime'
							defaultValue={data?.endTime}
							name='endTime'
							type='time'
							className='h-8 -mt-0.5'
						/>
					</div>
					<Input
						required
						label='intervalMinutes'
						defaultValue={data?.intervalMinutes}
						name='intervalMinutes'
						type='number'
						min='1'
						className='h-8 -mt-0.5'
					/>
					<DayOfWeeksInput defaultValue={data?.days} />

					<TextDivider text={'others'} className='pt-3' />
					<ReSelect
						className='h-8 -mt-0.5'
						label='team'
						name='teamId'
						defaultValue={data?.teamId}
						service={teamService}
					/>
				</div>
				<ControlButtons onReset={resetHandler} onCancel={onCancel} />
			</form>
		</div>
	);
};
export default Filters;
