import { useTranslations } from 'hooks';
import ControlButton from './ControlButton';

const ResizeButton = ({ onResize = () => {}, isResizing = false }) => {
	const { translate } = useTranslations();

	return (
		<ControlButton
			name={isResizing ? translate('apply') : translate('resize')}
			colorClass={isResizing ? 'text-red-400 hover:text-red-700' : undefined}
			icon='ri-drag-move-line'
			onClick={onResize}
		/>
	);
};
export default ResizeButton;
