const counters = [
	{
		title: 'patients',
		key: 'patients',
		icon: 'ri-user-heart-line',
		description: null,
		defaultNumber: '--',
		link: '/persons/patients',
	},
	{
		title: 'tasks',
		key: 'tasks',
		icon: 'ri-dashboard-fill',
		description: null,
		defaultNumber: '--',
		link: '/tasks/tasks',
	},
	{
		title: 'departments',
		key: 'departments',
		icon: 'ri-building-fill',
		description: null,
		defaultNumber: '--',
		link: '/persons/users/teams',
	},
	{
		title: 'teams',
		key: 'teams',
		icon: 'ri-team-line',
		description: null,
		defaultNumber: '--',
		link: '/persons/users/teams',
	},
	{
		title: 'users',
		key: 'users',
		icon: 'ri-user-line',
		description: null,
		defaultNumber: '--',
		link: '/persons/users/users',
	},
	{
		title: 'userProfiles',
		key: 'userProfiles',
		icon: 'ri-profile-line',
		description: null,
		defaultNumber: '--',
		link: '/persons/users/users',
	},
	{
		title: 'taskGroups',
		key: 'taskGroups',
		icon: 'ri-list-check-3',
		description: null,
		defaultNumber: '--',
		link: '/tasks/manage',
	},
	{
		title: 'assets',
		key: 'assets',
		icon: 'ri-book-2-fill',
		description: null,
		defaultNumber: '--',
		link: '/assets/assets',
	},
];
export default counters;
