import { Form, FormEntry } from 'components/form';
import { Loading } from 'components/ui/Interactive';
import { useCrud, useForm } from 'hooks';
import InputField from 'modules/tasks/components/InputField';
import TaskTemplateService from 'modules/tasks/pages/Manage/pages/TaskTemplates//pages/TaskTemplates/services';
import { Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Each } from 'utils/Each';

const TaskForm = forwardRef(({ isView = false, ...props }, ref) => {
	let service = props?.service;
	if (props?.data?.getServiceMethodName) {
		const getOneOverride = props?.service[props?.data?.getServiceMethodName].bind(props.service);
		const secondParamId = props?.data?.secondParamId || null;

		service = {
			...service,
			getOne: (id) => {
				if (!secondParamId) {
					return getOneOverride(id);
				}
				return getOneOverride(secondParamId, id);
			},
		};
	}

	const { getOne, fieldGating } = useCrud(service);
	const { getData } = useForm();

	const [fieldValues, setFieldValues] = useState({});
	const [extraParams, setExtraParams] = useState({
		returnTaskId: null,
		refTaskId: null,
		refTaskDirection: null,
	});

	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);

	const initiateData = () => {
		if (props?.data?.id) {
			getOne(props.data.id).then((res) => {
				if (res.fields) setFields(res.fields);

				setExtraParams({
					returnTaskId: res?.returnTaskId || null,
					refTaskId: res?.refTaskId || null,
					refTaskDirection: res?.refTaskDirection || null,
				});

				setLoading(false);
			});
		} else {
			if (props?.data.taskGroupId) {
				const taskTemplateService = new TaskTemplateService();
				taskTemplateService.setParent(props?.data.taskGroupId);

				taskTemplateService.viewOne(props?.data.taskTemplateId).then((res) => {
					if (res.data.fields) setFields(res.data.fields);
					setLoading(false);
				});
			}
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const getDataNoValidation = () => {
		const formData = new FormData(myForm.current);
		const formDataObject = Object.fromEntries(formData.entries());
		return formDataObject;
	};

	const resetData = () => {
		myForm.current.reset();
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(myForm.current),
		clear: resetData,
	}));

	const [fields, setFields] = useState([]);

	useEffect(() => {
		if (props?.data?.fields && props?.data?.fields.length > 0) {
			const fields = props?.data?.fields.map((field) => {
				return {
					...field,
					defaultValue: field.value || field.defaultValue,
				};
			});

			setFields(fields);
		}
	}, [props?.data?.fields]);

	const matchOptions = (value) => {
		if (!value) return [];
		const options = JSON.parse(value);

		return options.map((option) => {
			return { Id: option, Name: option };
		});
	};

	function debounce(func, wait) {
		let timeout;
		return function (...args) {
			return new Promise((resolve, reject) => {
				const later = () => {
					clearTimeout(timeout);
					try {
						resolve(func(...args));
					} catch (error) {
						reject(error);
					}
				};
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
			});
		};
	}

	const debouncedGetNoDataValidation = debounce(getDataNoValidation, 500);

	const handleChange = async (e, l_fieldValues) => {
		const data = await debouncedGetNoDataValidation();

		fieldGating(data).then((res) => {
			if (!res?.fields) return;
			const updatedFields = fields.map((field, index) => {
				const updatedField = res.fields.find((f) => f.id === field.id);

				if (updatedField) {
					if (updatedField.defaultValue === undefined) {
						return {
							...field,
							...updatedField,
							defaultValue: l_fieldValues[`fields[${index}].value`],
							value: l_fieldValues[`fields[${index}].value`],
						};
					} else {
						return {
							...field,
							...updatedField,
							value: updatedField.value || updatedField.defaultValue,
						};
					}
				}

				return {
					...field,
					defaultValue: l_fieldValues[`fields[${index}].value`],
					value: l_fieldValues[`fields[${index}].value`],
				};
			});

			setFields(updatedFields);
		});
	};

	useEffect(() => {
		const initialValues = {};
		fields.forEach((field, index) => {
			initialValues[`fields[${index}].value`] = props?.data.id ? field.value : field.defaultValue;
		});
		setFieldValues(initialValues);
	}, [fields, props?.data.id]);

	const handleFieldChange = (e, name, field, index) => {
		const value = e?.target ? e.target.value : e;

		const updatedFieldValues = {
			...fieldValues,
			[name]: fieldValueParser(field, value),
		};
		setFieldValues(updatedFieldValues);

		if (field.triggerDefaultSettings) {
			handleChange(e, updatedFieldValues);
		}
	};

	const fieldValueParser = (field, value) => {
		if (value === undefined || value === '') {
			return null;
		}

		if (field.inputType === 16) {
			return JSON.stringify(value);
		}

		if (field.inputType === 12 || field.inputType === 9) {
			// return JSON.stringify(value);
			return null;
		}

		return JSON.stringify([value]);
	};

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<div>Loading...</div>}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={props?.isClone ? false : props.data.id || false} />
					<input type='hidden' name='taskTemplateId' value={props?.data.taskTemplateId} />
					<input type='hidden' name={`taskGroupId`} value={props?.data.taskGroupId} />
					{Object.entries(extraParams).map(
						([key, value]) => value && <input key={key} type='hidden' name={key} value={value} />,
					)}

					<Each
						of={fields}
						render={(field, index) => {
							if (field?.isHidden) return null;
							return (
								<FormEntry
									label={field.name}
									isRequired={field.isRequired}
									key={'form-entry-' + index}
									className={'sm:block hidden w-[90%]'}
								>
									<input type='hidden' name={`fields[${index}].fieldId`} value={field.id} />
									<input type='hidden' name={`fields[${index}].inputType`} value={field.inputType} />
									<input
										type='hidden'
										name={`fields[${index}].triggerDefaultSettings`}
										value={field.triggerDefaultSettings}
									/>

									<InputField
										isView={isView}
										key={'input-field-' + field.id}
										label={field.name}
										name={`fields[${index}].value`}
										inputType={field.inputType}
										defaultData={fieldValues[`fields[${index}].value`]}
										options={
											field?.values ? JSON.parse(field?.values) : matchOptions(field?.options)
										}
										isRequired={field.isRequired}
										isDisabled={field.isDisabled}
										parentId={props?.data.taskGroupId}
										onChange={(e) => handleFieldChange(e, `fields[${index}].value`, field, index)}
									/>
								</FormEntry>
							);
						}}
					/>
				</Form>
			</div>
		</Suspense>
	);
});

export default TaskForm;
