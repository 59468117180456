export const convertData = (objectData) => {
	let toReturn = [];
	Object.keys(objectData).forEach((key) => {
		if (objectData[key] !== undefined) {
			toReturn.push({ key, value: objectData[key] });
		}
	});
	return toReturn;
};

export const convertStringArraysToNumbers = (data) => {
	Object.keys(data).forEach((key) => {
		if (Array.isArray(data[key])) {
			data[key] = data[key].map((item) => parseInt(item));
		}
	});
	return data;
};

export const exportDataToCSV = (data) => {
	const fileName = 'exported_data' + new Date().getTime() + '.csv';
	const csvData = convertToCSV(data);
	const encodedUri = encodeURI(csvData);
	const link = document.createElement('a');
	link.setAttribute('href', encodedUri);
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

const convertToCSV = (data) => {
	if (data.length === 0) return '';

	const headers = Object.keys(data[0]);
	const csv = [
		headers.join(','), // Add headers as the first row
		...data.map((d) => headers.map((key) => d[key]).join(',')), // Map data values
	].join('\n');

	return 'data:text/csv;charset=utf-8,' + csv;
};
