import { Button, CheckBox } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import { useState } from 'react';
import { Each } from 'utils/Each';

const ManageChartForm = ({ data, onChartChange = () => {}, onApplyChanges = () => {} }) => {
	const { translate } = useTranslations();
	const [charts, setCharts] = useState(data.charts);
	const defaultCharts = data.defaultCharts;

	const onCheckChange = (index, checked) => {
		const newCharts = [...charts];
		newCharts[index].hidden = !checked;
		setCharts(newCharts);
		onChartChange(newCharts);
	};

	return (
		<div className='w-full h-100 pb-10 pl-5 overflow-y-visible'>
			<div className='pt-1'>
				<Each
					of={charts}
					render={(column, index) => {
						return (
							<div className='flex flex-row items-center'>
								<div>
									<CheckBox
										checkboxSize='text-lg'
										selected={!column.hidden}
										label={translate(column.key)}
										onChange={(e) => {
											onCheckChange(index, e.target.checked);
										}}
									/>
								</div>
							</div>
						);
					}}
				/>
			</div>
			<div
				className='flex justify-end bottom-5 absolute right-5'
				onClick={() => {
					onApplyChanges(charts);
				}}
			>
				<Button>{translate('apply')}</Button>
			</div>
		</div>
	);
};

export default ManageChartForm;
