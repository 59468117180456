import { forwardRef, useEffect, useState } from 'react';
import CounterRender from './components/CounterRender';
import contents from './contents';
import utils from './utils';

const getKey = (item) => {
	const excMap = [{ key: 'userProfiles', exc: 'totalProfiles' }];
	if (excMap.find((e) => e.key === item)) return excMap.find((e) => e.key === item).exc;

	return 'total' + item.charAt(0).toUpperCase() + item.slice(1);
};
const Counter = forwardRef(({ item = 'patients', ...props }, ref) => {
	const [summary, setSummary] = useState(null);
	useEffect(() => {
		utils.getSummary(getKey(item)).then((data) => {
			setSummary(data);
		});
	}, []);
	const content = contents.find((c) => c.key === item);
	if (!content) return null;

	return <CounterRender {...content} number={summary || content.defaultNumber} {...props} ref={ref} />;
});
export default Counter;
