const ControlButton = ({ name, icon, onClick = () => {}, colorClass = 'text-gray-700 hover:text-blue-500 ' }) => {
	return (
		<button
			onClick={onClick}
			className={`items-center justify-center flex focus:outline-none md:order-1 ${colorClass}`}
		>
			<i class={`${icon} text-2xl`}></i>
			<span className='text-xs pl-1 hidden md:inline  '>{name}</span>
		</button>
	);
};
export default ControlButton;
