import { useEffect, useState } from 'react';
import PermissionsContext from './PermissionsContext';

import { runAction } from 'modules/utils';

import { PERMISSION_LICENSE_FEATURES } from 'config/configs';

const getPermissionsOfLicenses = (licenseFeatures) => {
	const permissions = {};
	PERMISSION_LICENSE_FEATURES.forEach((feature) => {
		permissions[feature.permissionKey] = licenseFeatures[feature.licenseKey] || false;
	});
	return permissions;
};

const PermissionsProvider = ({ children }) => {
	const [permissions, setPermissions] = useState(null);

	const loadPermissions = async () => {
		let permissions = await runAction('auth', 'permissions');
		const settings = await runAction('tenants', 'getSettings');

		if (settings?.licenseFeatures) {
			permissions['licenseFeatures'] = getPermissionsOfLicenses(settings?.licenseFeatures);
		}
		setPermissions(permissions);
	};

	useEffect(() => {
		const handleProfileSwitch = () => {
			loadPermissions();
		};

		window.addEventListener('profileSwitch', handleProfileSwitch);

		if (!permissions) loadPermissions();

		return () => {
			window.removeEventListener('profileSwitch', handleProfileSwitch);
		};
	}, [permissions]);

	return <PermissionsContext.Provider value={permissions}>{children}</PermissionsContext.Provider>;
};

export default PermissionsProvider;
