import PropTypes from 'prop-types';
import {
	Bar,
	CartesianGrid,
	ComposedChart,
	Legend,
	Line,
	Rectangle,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

const BarChartComposed = ({
	data,
	legends,
	displayLegends = true,
	xAxisLabel = 'label',
	yAxisLabel = null,
	xAxisProps = {},
}) => {
	const getLegend = (key) => {
		return legends.find((legend) => legend.key === key);
	};

	const renderLegend = (props) => {
		const { payload } = props;
		return (
			<ul className='flex space-x-4'>
				{payload.map((entry, index) => (
					<li key={`item-${index}`} className='flex items-center space-x-2'>
						<div
							style={{
								width: 10,
								height: 10,
								backgroundColor: entry.color,
							}}
						></div>
						<span>{getLegend(entry.value)?.label || entry.value}</span>
					</li>
				))}
			</ul>
		);
	};

	const tooltipFormatter = (value, name) => {
		return [value, getLegend(name)?.label || name];
	};

	return (
		<ResponsiveContainer>
			<ComposedChart
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray='3 3' />
				<XAxis dataKey={xAxisLabel} {...xAxisProps} />
				<YAxis dataKey={yAxisLabel} />
				<Tooltip formatter={tooltipFormatter} />
				{displayLegends && <Legend content={renderLegend} />}

				{legends?.map((legend, index) => {
					if (legend.chartType === 'Line') {
						return <Line key={index} type={legend.type} dataKey={legend.key} stroke={legend.stroke} />;
					} else if (legend.chartType === 'Bar') {
						return (
							<Bar
								key={index}
								dataKey={legend.key}
								maxBarSize={legend.maxBarSize}
								stackId={legend.stackId || null}
								fill={legend.fill}
								activeBar={<Rectangle fill={legend.activeBar.fill} stroke={legend.activeBar.stroke} />}
							/>
						);
					}
					return null;
				})}
			</ComposedChart>
		</ResponsiveContainer>
	);
};

BarChartComposed.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	legends: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			chartType: PropTypes.oneOf(['Line', 'Bar']).isRequired,
			type: PropTypes.string, // For Line chart
			stroke: PropTypes.string, // For Line chart
			fill: PropTypes.string, // For Bar chart
			maxBarSize: PropTypes.number, // For Bar chart
			activeBar: PropTypes.shape({
				fill: PropTypes.string,
				stroke: PropTypes.string,
			}), // For Bar chart
		}),
	).isRequired,
};

export default BarChartComposed;
