import ConnectionView from './ADSetup/parts/ConnectionView';
import EntraIdConnectionView from './EntraIdSetup/parts/EntraIdConnectionView';
const SetupView = ({ permissions }) => {
	return (
		<div className='grid grid-cols-1 gap-2 '>
			{permissions?.ActiveDirectory?.View && <ConnectionView permissions={permissions?.ActiveDirectory} />}
			{permissions?.EntraId?.View && <EntraIdConnectionView permissions={permissions?.EntraId} />}
		</div>
	);
};
export default SetupView;
