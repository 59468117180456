import { AddressColumn, IdColumn, SimpleObjectColumn, StatusColumn } from 'core/columns';
import { DateColumn, StringColumn } from 'core/columns/baseColumns';
import BaseModel from 'core/models/BaseModel';
class PatientModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'patient';
		this.pluralModelName = 'patients';
	}
	initializeColumns() {
		this.columns.push(new IdColumn());
		this.columns.push(
			new StringColumn('fullName', {
				label: 'fullName',
				filter: {
					type: 'text',
					key: 'FullName',
				},
			}),
		);
		this.columns.push(
			new StringColumn('patientNr', {
				label: 'patientNr',
				filter: {
					type: 'text',
					key: 'PatientNr',
				},
			}),
		);
		this.columns.push(
			new StringColumn('admissionNr', {
				label: 'admissionNr',
				filter: {
					type: 'text',
					key: 'AdmissionNr',
				},
			}),
		);
		this.columns.push(new AddressColumn('homeLocation')); //by default it is 2, i left here for sample
		this.columns.push(new SimpleObjectColumn('gender', 'name'));
		this.columns.push(
			new StatusColumn(
				'status',
				{ 0: 'lightBlue', 1: 'red', 2: 'green', 3: 'purple' },
				{
					filter: {
						type: 'select',
						key: 'status',
						options: [
							{
								label: 'all',
								value: '0',
							},
							{
								label: 'PreAdmitted',
								value: '1',
							},
							{
								label: 'Admitted',
								value: '2',
							},
							{
								label: 'Discharged',
								value: '3',
							},
							{
								label: 'TemporarilyLeave',
								value: '4',
							},
						],
						defaultValue: 2,
					},
				},
			),
		);
		this.columns.push(new DateColumn('updatedOn'));
		this.columns.push(
			new DateColumn('eventDateTime', {
				filter: {
					type: 'date',
					key: 'EventDateTime',
				},
			}),
		);
		this.assignColumnOrder();
	}

	isExportable() {
		return false;
	}

	areSelectsVisible() {
		return false;
	}
}
export default PatientModel;
