import NewNode from './NewNode';
import NodeLoader from './NodeLoader';

const RootNode = ({
	rootLevel = 6,
	allowMultipleSite = false,
	permissions,
	onAddOpen = () => {},
	loading = false,
	children,
}) => {
	// ================================================
	// COMPONENT
	// ================================================
	return (
		<>
			{loading ? (
				<NodeLoader level={0} rootLevel={rootLevel} />
			) : (
				<>
					{children}
					{!permissions?.Create || (rootLevel === 4 && !allowMultipleSite) ? null : (
						<NewNode onClick={() => onAddOpen()} level={rootLevel} rootLevel={rootLevel} />
					)}
				</>
			)}
		</>
	);
};

export default RootNode;
