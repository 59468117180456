import EmployeeStatsChart from '../components/Main/Charts/EmployeeStats/EmployeeStatsChart';
import TaskStatsPerEmployeeChart from '../components/Main/Charts/TaskStatsPerEmployee/TaskStatsPerEmployeeChart';
import TaskStatsPerTeamChart from '../components/Main/Charts/TaskStatsPerTeam/TaskStatsChart';
import Counter from '../components/Main/Counters';
const componentMap = [
	{
		key: 'PatientsCounter',
		type: 'counter',
		hidden: false,
		component: Counter,
		props: { item: 'patients' },
		layouts: {
			lg: { x: 0, y: 0, w: 3, h: 4, minW: 2, maxW: 4, minH: 4, maxH: 7 },
			md: { x: 0, y: 0, w: 5, h: 4, minW: 2, maxW: 5, minH: 4, maxH: 7 },
			sm: { x: 0, y: 0, w: 6, h: 4, minW: 4, maxW: 6, minH: 4, maxH: 7 },
		},
	},
	{
		key: 'TasksCounter',
		type: 'counter',
		hidden: false,
		component: Counter,
		props: { item: 'tasks' },
		layouts: {
			lg: { x: 3, y: 0, w: 3, h: 4, minW: 2, maxW: 4, minH: 4, maxH: 7 },
			md: { x: 5, y: 0, w: 5, h: 4, minW: 2, maxW: 5, minH: 4, maxH: 7 },
			sm: { x: 0, y: 4, w: 6, h: 4, minW: 4, maxW: 6, minH: 4, maxH: 7 },
		},
	},
	{
		key: 'AssetsCounter',
		type: 'counter',
		hidden: false,
		component: Counter,
		props: { item: 'assets' },

		layouts: {
			lg: { x: 6, y: 0, w: 3, h: 4, minW: 2, maxW: 4, minH: 4, maxH: 7 },
			md: { x: 0, y: 4, w: 5, h: 4, minW: 2, maxW: 5, minH: 4, maxH: 7 },
			sm: { x: 0, y: 8, w: 6, h: 4, minW: 4, maxW: 6, minH: 4, maxH: 7 },
		},
	},
	{
		key: 'TeamsCounter',
		type: 'counter',
		hidden: false,
		component: Counter,
		props: { item: 'teams' },
		layouts: {
			lg: { x: 9, y: 0, w: 3, h: 4, minW: 2, maxW: 4, minH: 4, maxH: 7 },
			md: { x: 5, y: 4, w: 5, h: 4, minW: 2, maxW: 5, minH: 4, maxH: 7 },
			sm: { x: 0, y: 12, w: 6, h: 4, minW: 4, maxW: 6, minH: 4, maxH: 7 },
		},
	},
	{
		key: 'UsersCounter',
		type: 'counter',
		hidden: true,
		component: Counter,
		props: { item: 'users' },
		layouts: {
			lg: { x: 0, y: 4, w: 3, h: 4, minW: 2, maxW: 4, minH: 4, maxH: 7 },
			md: { x: 0, y: 8, w: 5, h: 4, minW: 2, maxW: 5, minH: 4, maxH: 7 },
			sm: { x: 0, y: 16, w: 6, h: 4, minW: 4, maxW: 6, minH: 4, maxH: 7 },
		},
	},
	{
		key: 'UserProfilesCounter',
		type: 'counter',
		hidden: true,
		component: Counter,
		props: { item: 'userProfiles' },
		layouts: {
			lg: { x: 3, y: 4, w: 3, h: 4, minW: 2, maxW: 4, minH: 4, maxH: 7 },
			md: { x: 5, y: 8, w: 5, h: 4, minW: 2, maxW: 5, minH: 4, maxH: 7 },
			sm: { x: 0, y: 20, w: 6, h: 4, minW: 4, maxW: 6, minH: 4, maxH: 7 },
		},
	},
	{
		key: 'TaskGroupsCounter',
		type: 'counter',
		hidden: true,
		component: Counter,
		props: { item: 'taskGroups' },
		layouts: {
			lg: { x: 6, y: 4, w: 3, h: 4, minW: 2, maxW: 4, minH: 4, maxH: 7 },
			md: { x: 0, y: 12, w: 5, h: 4, minW: 2, maxW: 5, minH: 4, maxH: 7 },
			sm: { x: 0, y: 24, w: 6, h: 4, minW: 4, maxW: 6, minH: 4, maxH: 7 },
		},
	},
	{
		key: 'DepartmentsCounter',
		type: 'counter',
		hidden: true,
		component: Counter,
		props: { item: 'departments' },
		layouts: {
			lg: { x: 9, y: 4, w: 3, h: 4, minW: 2, maxW: 4, minH: 4, maxH: 7 },
			md: { x: 5, y: 12, w: 5, h: 4, minW: 2, maxW: 5, minH: 4, maxH: 7 },
			sm: { x: 0, y: 28, w: 6, h: 4, minW: 4, maxW: 6, minH: 4, maxH: 7 },
		},
	},
	{
		key: 'TaskStatsPerTeamChart',
		type: 'chart',
		hidden: false,
		showLegends: true,
		component: TaskStatsPerTeamChart,
		layouts: {
			lg: { x: 0, y: 8, w: 6, h: 10, minW: 4, maxW: 12, minH: 11, maxH: 15 },
			md: { x: 0, y: 16, w: 10, h: 10, minW: 4, maxW: 10, minH: 11, maxH: 15 },
			sm: { x: 0, y: 32, w: 6, h: 10, minW: 4, maxW: 6, minH: 11, maxH: 15 },
		},
	},
	{
		key: 'TaskStatsPerEmployeeChart',
		type: 'chart',
		hidden: false,
		showLegends: true,
		component: TaskStatsPerEmployeeChart,
		layouts: {
			lg: { x: 6, y: 8, w: 6, h: 10, minW: 4, maxW: 12, minH: 11, maxH: 15 },
			md: { x: 0, y: 26, w: 10, h: 10, minW: 4, maxW: 10, minH: 11, maxH: 15 },
			sm: { x: 0, y: 42, w: 6, h: 10, minW: 4, maxW: 6, minH: 11, maxH: 15 },
		},
	},
	{
		key: 'EmployeeStatsChart',
		type: 'chart',
		hidden: false,
		showLegends: true,
		component: EmployeeStatsChart,
		layouts: {
			lg: { x: 0, y: 18, w: 12, h: 13, minW: 8, maxW: 12, minH: 11, maxH: 20 },
			md: { x: 0, y: 36, w: 10, h: 13, minW: 8, maxW: 10, minH: 11, maxH: 20 },
			sm: { x: 0, y: 52, w: 6, h: 13, minW: 4, maxW: 6, minH: 11, maxH: 20 },
		},
	},
];

export default componentMap;
