import { Button } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import ControlButton from './components/ControlButton';
import MoveButton from './components/MoveButton';
import ResizeButton from './components/ResizeButton';

const ControlBar = ({
	onManageChart = () => {},
	onManageCounter = () => {},
	onResetDefault = () => {},
	onResizeClick = () => {},
	onMoveClick = () => {},
	onRefresh = () => {},
	onCancel = () => {},
	cancelVisible = false,
	isMoving = false,
	isResizing = false,
}) => {
	const { translate } = useTranslations();

	return (
		<div className='fixed bottom-0 left-0 right-0 bg-white shadow-md p-2 flex justify-around items-center md:justify-end md:space-x-4 '>
			<ControlButton name={translate('manageCharts')} icon='ri-bar-chart-box-ai-line' onClick={onManageChart} />
			<ControlButton name={translate('manageCounters')} icon='ri-info-card-line' onClick={onManageCounter} />
			<ResizeButton onResize={onResizeClick} isResizing={isResizing} />
			<MoveButton onMove={onMoveClick} isMoving={isMoving} />
			<ControlButton name={translate('refresh')} icon='ri-refresh-line' onClick={onRefresh} />
			<ControlButton name={translate('resetDefault')} icon='ri-arrow-go-back-line' onClick={onResetDefault} />

			{cancelVisible && (
				<div className='pr-5'>
					<Button color='secondary' className='pt-1.5 pb-2 pr-2 pl-2 text-xs' onClick={onCancel}>
						{translate('cancel')}
					</Button>
				</div>
			)}
		</div>
	);
};

export default ControlBar;
