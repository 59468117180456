import { useCallback, useEffect, useState } from 'react';

import { Each } from 'utils/Each';

import { useAside, useTranslations } from 'hooks';
import { mapWithDistances } from 'modules/addresses/models/address.model';
import { create, loadTreeRoot } from 'modules/addresses/services/addresses';
import { actionAndReload, getTranslatedName } from './utils';

import AddressForm from '../../forms/address.form';
import NodeControl from './NodeControl';
import RootNode from './RootNode';

const RootNodeControl = ({ rootLevel, allowMultipleSite = false, permissions }) => {
	// ================================================
	// Hooks
	// ================================================
	const { translate } = useTranslations();
	const { asideBuilder } = useAside();

	// ================================================
	// State
	// ================================================
	const [tree, setTree] = useState(undefined);

	// ================================================
	// Effects
	// ================================================
	const loadRoot = useCallback(async () => {
		if (!rootLevel) return;
		let res = await loadTreeRoot(rootLevel);
		if (rootLevel < 6) {
			for (let i = 6; i > rootLevel; i--) {
				if (res?.length > 0) {
					res = res[0].children;
				}
			}
		}
		setTree(res);
	}, [rootLevel]);

	const hardReloadRoot = useCallback(async () => {
		if (tree !== undefined) setTree(undefined);
		loadRoot();
	}, [tree, loadRoot]);

	useEffect(() => {
		loadRoot();
	}, [loadRoot]);

	// ================================================
	// Component Services
	// ================================================
	const createAndReload = useCallback(
		async (data) =>
			await actionAndReload(
				async () => await create[rootLevel](null, mapWithDistances(data)),
				hardReloadRoot,
				translate,
				'ThisCreatedSuccessfully',
				rootLevel,
			),
		[rootLevel, hardReloadRoot, translate],
	);

	const openNewRoot = () => {
		asideBuilder.setTitle(`New ${getTranslatedName(translate, rootLevel)}`);
		asideBuilder.setComponent(AddressForm);
		asideBuilder.setComponentKey(`new-address-${rootLevel}-${new Date()}`);
		asideBuilder.setComponentProps({
			type: getTranslatedName(translate, rootLevel),
			level: rootLevel,
			siblings: tree,
		});
		asideBuilder.setSaveCallback(createAndReload);
		asideBuilder.setOpen(true);
		asideBuilder.build();
	};

	return (
		<RootNode
			rootLevel={rootLevel}
			onAddOpen={openNewRoot}
			allowMultipleSite={allowMultipleSite}
			loading={tree === undefined}
			permissions={permissions}
		>
			<Each
				of={tree}
				render={(item, index) => {
					return (
						<NodeControl
							{...item}
							index={index}
							rootLevel={rootLevel}
							permissions={permissions}
							parentReload={async () => hardReloadRoot()}
						/>
					);
				}}
			/>
		</RootNode>
	);
};

export default RootNodeControl;
